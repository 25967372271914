@font-face {
  font-family: 'Inter';
  src:
    local('Inter-Regular'),
    url(./assets/fonts/Inter-Regular-slnt=0.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-Bold';
  src:
    local('Inter-Bold'),
    url(./assets/fonts/Inter-Bold-slnt=0.ttf) format('truetype');
}

@import './styles/colors.scss';
@import './styles/tab.scss';

body {
  margin: 0;
  color: $neutral0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  overflow-y: auto !important;
  background-color: $neutral_background;
}

.main-container {
  display: flex;
}

a {
  text-decoration: none;
}

.link.base2 {
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 16px;
}

.link.base2.active {
  cursor: pointer;
  background: $neutral3;
  border-radius: 8px;
  padding: 8px 16px;
}

.row {
  display: flex;
  flex-direction: row;
}

.align-center {
  align-items: center;
}
.right {
  padding-top: 3vh;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-bottom: 150px;
}
.space-between {
  justify-content: space-between;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
}

.divider {
  border: 0px;
  border-top: 1px solid $neutral3;
  margin-top: 30px;
  margin-bottom: 20px;
}

.title1SemiBold {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: $neutral7;
}

.base2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: $neutral5;
}

.input__title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -1%;
  color: $neutral5;
  margin: 10px 0px;
  .input__title__checkbox {
    display: inline;
  }
}

.input {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  background-color: $neutral2;
  color: $neutral5;
}

.body1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: $neutral4;

  &-header {
    color: $neutral5;
  }
}

.caption1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.01em;
}

.caption2medium {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.01em;
  color: $neutral7;
}

.caption2bold {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: $neutral7;
}

h1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.02em;
  color: $neutral7;
  text-align: left;
  margin: 0px 0px 0px 0px;
}

h2 {
  font-family: 'Inter';
  font-weight: 'bold';
  font-size: 26px;
  color: $neutral7;
}
h3 {
  font-weight: 'bold';
  font-family: 'Inter';
  font-size: 20px;
  color: $neutral7;
}
h4 {
  font-weight: 'bold';
  font-family: 'Inter';
  font-size: 16px;
  color: $neutral7;
}

h5 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: $neutral7;
}

h6 {
  font-weight: 700;
  font-family: 'Inter';
  font-size: 13px;
}

.heading6 {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.4px;
  text-align: left;
  color: $neutral5;
}

.p1 {
  font-size: 16px;
}
.p2 {
  font-size: 13px;
}

.p3 {
  font-size: 14px;
}

.row {
  display: 'flex';
  flex-direction: 'row';
}

.tooltip {
  background-color: $grey900;
}

.link {
  color: $primary;
  font-weight: bold;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.sectionTitle {
  margin-top: 40px;
  margin-bottom: 20px;
}
.panelContent {
  border: 1px solid #dcdcdc;
  padding: 18px;
  border-bottom-width: 0px;
}

.panelTitle {
  display: 'flex';
  flex-direction: 'row';
  justify-content: 'space-between';

  background-color: $grey100;
  border: 1px solid #dcdcdc;
  padding: 18px;
}

.noborder-top {
  border-top: 0px;
}

.quantityLine {
  display: flex;
  flex-direction: row;
}

.quantityLine > div {
  width: 50%;
}

.panelRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.panelTitleText {
  color: $grey0;
  font-weight: 500;
  margin-bottom: 4px;
}

.panelSubTitle {
  color: $grey500;
}

.closeBtn {
  justify-content: 'center';
  margin-right: 30px;
  background-color: $grey100;
  border-radius: 12px;
  padding: 19px;
}

.addBtn {
  cursor: pointer;
  background: $primary1;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  /* Inside auto layout */

  flex: none;
  order: 4;
  flex-grow: 0;
  margin: 0px 8px;

  .base2 {
    color: $neutral1;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 4px;
  }

  .svgPlus {
    height: 23px;
    width: 23px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 4px;
    fill: $neutral1;
    stroke: $neutral1;
  }
}

.HRSep {
  display: block;
  width: 90%;
  border: 0;
  border-top: 1px solid $neutral3;
  margin: 25px 20px 10px 20px;
}

.svg.icon {
  fill: $neutral4;
  position: absolute;
  padding: 5px;
  min-width: 40px;
}

.importExportBtn {
  cursor: pointer;
  background: $neutral3;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  /* Inside auto layout */

  flex: none;
  order: 4;
  flex-grow: 0;
  margin: 0px 8px;

  .base2 {
    color: $neutral7;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 4px;
  }

  .svgPlus {
    height: 23px;
    width: 23px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 4px;
    fill: $neutral1;
    stroke: $neutral1;
  }
}

.filterBtn {
  cursor: pointer;
  background: $neutral1;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  flex: none;
  order: 4;
  flex-grow: 0;
  margin: 0px 8px;

  border: 1px solid $neutral3;
  box-sizing: border-box;
  border-radius: 8px;

  .base2 {
    color: $neutral4;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 4px;
  }

  .svgFilter {
    height: 23px;
    width: 23px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 4px;
    fill: $neutral4;
  }
}

.filterBtn.active {
  background: $secondary1;

  .base2 {
    color: $neutral1;
  }

  .svgFilter {
    fill: $neutral1;
    stroke: $neutral1;
  }
}

.cancelBtn {
  cursor: pointer;
  background: $neutral1;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 16px;
  border-radius: 8px;

  .base2 {
    color: $neutral7;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 4px;
  }
}

.notConnectedSxdBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 16px;

  cursor: pointer;
  background: $neutral1;
  border: 1px solid $neutral3;
  box-sizing: border-box;
  border-radius: 8px;

  .base2 {
    color: $neutral7;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 4px;
  }

  .svgConnexionSxd {
    fill: $neutral7;
    height: 23px;
    width: 23px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 4px;
  }
}

.connectedSxdBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 16px;

  cursor: pointer;
  background: $primary3;
  border: 1px solid $primary3;
  box-sizing: border-box;
  border-radius: 8px;

  .base2 {
    color: $neutral0;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 4px;
  }

  .svgConnexionSxd {
    fill: $neutral0;
    height: 23px;
    width: 23px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 4px;
    color: $neutral0;
  }
}

.saveBtnSuccess {
  background: $primary1;

  cursor: pointer;
  background: $primary3;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  flex: none;
  order: 4;
  flex-grow: 0;
  margin: 0px 8px;

  border: 1px solid $neutral3;
  box-sizing: border-box;
  border-radius: 8px;

  .base2 {
    color: $neutral1;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 4px;
  }

  .svgSave {
    fill: $neutral1;
    height: 23px;
    width: 23px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 4px;
  }
}

.saveBtnDisable {
  background: $neutral4;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  flex: none;
  order: 4;
  flex-grow: 0;
  margin: 0px 8px;

  border: 1px solid $neutral4;
  box-sizing: border-box;
  border-radius: 8px;

  .base2 {
    color: $neutral0;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 4px;
  }

  .svgSave {
    fill: $neutral0;
    height: 23px;
    width: 23px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 4px;
  }
}

.saveBtn {
  background: $primary1;

  cursor: pointer;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  flex: none;
  order: 4;
  flex-grow: 0;
  margin: 0px 8px;

  border: 1px solid $neutral3;
  box-sizing: border-box;
  border-radius: 8px;

  .base2 {
    color: $neutral1;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 4px;
  }

  .svgSave {
    fill: $neutral1;
    height: 23px;
    width: 23px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 4px;
    fill: $neutral1;
  }
}

.validateBtn {
  cursor: pointer;
  justify-content: 'left';
  align-items: 'left';
  border: 1px solid $primary;
  border-radius: 4px;
  background-color: $primary;

  padding: 12px;
  width: fit-content;
  height: fit-content;
  margin-top: 30px;

  padding: 15px 41px;
  a {
    text-decoration: none;
  }
  h6 {
    color: white;
    margin: 0px;
  }
}

.validateBtn.alternate {
  cursor: pointer;
  justify-content: 'center';
  align-items: 'center';
  border: 1px solid $primary;
  border-radius: 4px;
  background-color: white;

  padding: 12px;
  width: fit-content;
  height: fit-content;
  margin-top: 30px;

  padding: 15px 41px;
  h6 {
    color: $primary;
    margin: 0px;
  }
}

.btn {
  cursor: pointer;
  justify-content: 'center';
  align-items: 'center';
  border: 1px solid $primary;
  border-radius: 4px;
  background-color: $primary;

  width: fit-content;
  height: fit-content;

  padding: 15px 41px;
  a {
    text-decoration: none;
  }
  h6 {
    color: white;
    margin: 0px;
  }
}
.card {
  border-radius: 12px;
  padding: 16px;
  display: 'flex';
  flex-direction: 'row';
}
.cardIconContainer {
  width: 60px;
  height: 60px;
  border-radius: 18px;
  align-content: 'center';
  justify-content: 'center';
  margin-right: 16px;
}

input {
  padding: 8px 20px;
  border-radius: 5px;
  border: 1px solid $grey400;
  box-sizing: border-box;
}

input::placeholder {
  color: $grey500;
}
.grey500 {
  color: $grey500;
}

.blackButton {
  background-color: #2d2d2d;
  border-radius: 12px;
  padding: 57px 16px;
}

.firstColumn {
  color: $black900;
}

.checkbox {
  cursor: pointer;
}

.buttonIcon {
  cursor: pointer;
}

.hide {
  display: none;
}

.red-badge {
  background-color: #e74c3c;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-top: 0px;
  margin-left: 1px;
}

.TitleText {
  background-color: $neutral_background;
  justify-content: left;
  align-items: center;
  text-align: left;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 24px 0px;
  h1 {
    text-align: left;
  }
}

.validator-error {
  color: $primary4;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.returnBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  margin: 0 20px;

  background: #ffffff;
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 0;

  .svg {
    fill: $neutral7;
    stroke: $neutral7;
  }
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px;
  width: 100%;

  &-align-left {
    align-items: flex-start;
    text-align: left;
  }

  &-align-center {
    align-items: center;
    text-align: center;
  }

  &-align-right {
    align-items: flex-end;
    text-align: right;
  }

  &-last {
    align-items: flex-end;
    min-width: none;
  }

  .select-input {
    width: 100%;
  }

  .firstRow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 16px;

    .caption2medium {
      text-align: left;
    }
  }
}

.filterMenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 410px;
  z-index: 100;

  /* Neutral/01 */

  background: #fcfcfc;
  /* Depth 01 */

  box-shadow:
    0px 0px 14px -4px rgba(0, 0, 0, 0.05),
    0px 32px 48px -8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(32px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 16px;

  .filterMenu_cat_title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    size: 14px;
    margin: 0px 12px 0px 27px;

    .base2 {
      color: $neutral4;
      margin-left: 7px;
    }

    .filterMenu_cat_checkbox {
      border: 1px solid $neutral3;
      border-radius: 4px;
    }

    .filterMenu_cat_text {
      border: 1px solid $neutral3;
      border-radius: 5px;
      width: 90%;
      background-color: $neutral3;
    }
  }

  .filterMenu_cat_content {
    justify-content: center;
    align-items: center;
  }

  .filterMenu_cat_select {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .svgBox {
      width: 85%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0 12;
      border-radius: 8px;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      margin: 0px 0px;

      .select-input {
        width: 100%;
      }
      .svg {
        fill: $neutral4;
        width: 38px;
      }
    }
  }

  .filterMenu_cat_input {
    width: 100%;
    input {
      width: 90%;
      margin: 0px 10px;
    }
  }

  .filterMenu_cat_choise {
    justify-content: flex-start;
    align-items: center;
    margin: 0px 12px 0px 27px;
    size: 14px;

    .filterMenu_cat_checkbox {
      border: 1px solid $neutral3;
      border-radius: 4px;
    }
  }

  .filterMenu_cat {
    width: 100%;

    .svgCloseBox {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 0 20;
      border-radius: 8px;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      margin: 0 12 0 0;
      .svg {
        fill: $neutral4;
        width: 38px;
      }
    }
  }

  .filterMenu_footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 12px 0px;
    box-shadow: inset 0px 1px 0px #efefef;
  }
}

.form {
  width: 100%;
}

.form-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  padding: 10px 12px;
}

.fill {
  flex: 1 0 0;
  text-align: left;
}
