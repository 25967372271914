.dune-fix {
  display: flex;
  height: 45px;
  background-color: white;
  justify-content: flex-end;
  position: fixed;
  width: calc(100%);
  z-index: 2;
  padding: 25px 25px;
  bottom: 0;
  right: 0;
  align-items: center;
  &-button {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    gap: 10px;
  }
}
