.leftImage {
  content: '&nbsp';
  background: url('./assets/dune-2.jpg') no-repeat;
  background-size: cover;
}

.height-minus-8 {
  height: calc(100% - 8px);
}

/* h-screen - padding-top - h2 - gap - ActionBar - gap - Toolbar - padding-bottom */
.height-with-toolbar-and-actionbar {
  height: calc(100vh - 20px - 52px - 20px - 64px - 20px - 68px - 20px);
}

/* h-screen - padding-top - h2 - gap - Toolbar - padding-bottom */
.height-with-toolbar {
  height: calc(100vh - 20px - 52px - 20px - 68px - 20px);
}

/* h-screen - padding-top - h2 - gap - Tabs - gap - Toolbar - padding-bottom */
.height-with-tabs-and-toolbar {
  height: calc(100vh - 20px - 52px - 20px - 56px - 20px - 68px - 20px);
}

/* h-screen - padding-top - h2 - gap - Tabs - gap - Actionbar - gap - Toolbar - gap - padding-bottom */
.height-with-tabs-actionbar-and-toolbar {
  height: calc(100vh - 20px - 52px - 20px - 76px - 20px - 54px - 20px - 68px - 10px);
}

/* h-screen - navbar - padding-top - tabs - gap - padding-bottom */
.height-tabs-content[data-state='active'] {
  height: calc(100vh - 90px - 10px - 56px - 10px - 10px);
}

.height-ancillary-task {
  height: calc(100vh - 20px - 52px - 46px - 20px - 48px - 50px);
}

.height-non-accreditation {
  height: calc(100vh - 20px - 52px - 46px - 20px - 48px - 50px - 130px);
}

/* h-screen - padding-top - h2 - gap - Tabs - gap - Info - gap - Actionbar - gap - Toolbar - gap - padding-bottom */
.height-oil-properties-airport {
  height: calc(100vh - 20px - 52px - 20px - 56px - 20px - 70px - 20px - 64px - 20px - 68px - 20px);
}

/* h-screen - padding-top - h2 - gap - - Actionbar - gap - DN Tabs  - gap  - Actionbar -  padding-bottom */
.height-deliveryNote-modification-history {
  height: calc(100vh - 20px - 52px - 20px - 64px - 20px - 46px - 20px - 64px - 80px);
}

/* h-screen - padding-top - h2 - gap - tabs - padding-bottom */
.height-with-tabs {
  height: calc(100vh - 20px - 52px - 20px - 56px - 20px);
}

.switch-margin-top {
  margin-top: 16px !important;
}

[data-radix-scroll-area-viewport] > div[style*='display: table;'] {
  display: block !important;
}

.td-modal {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}
