@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 36 24% 96%; /* #f7f5f2 */
    --foreground: 227 27% 27%; /* TEXT #333B59 */

    --muted: 215 56% 34%; /* BLUE #264E86 */
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 227 27% 27%; /* TEXT #333B59 */

    --border: 215 21% 64%; /* GRAY TEXT #90A0B7 */
    --input: 215 21% 64%; /* GRAY TEXT #90A0B7 */

    --card: 0 0% 100%;
    --card-foreground: 227 27% 27%; /* TEXT #333B59 */

    --primary: 214 100% 58%; /* BLUE #2a85ff */
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 215 56% 34%; /* BLUE #264E86 */

    --alternative: 238 56% 57%; /* Violet #565bcf */
    --alternative-foreground: 210 40% 96.1%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 227 27% 27%; /* TEXT #333B59 */

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --success: 121 43.9% 58%; /* TEXT #65c366 */
    --success-foreground: 210 40% 98%;

    --notification: 8 87.8% 58.2%;
    --notification-foreground: 8 87.8% 58.2% /* TEXT #F26F37*/;

    --ring: 0 0% 6%; /* BLACK #101010 */

    --radius: 0.5rem;

    --gray-text: 215 21% 64%; /* GRAY TEXT #90A0B7 */
    --gray-backgroud-menu: 215 15% 84%; /* GRAY BACKGROUND #D0D5DC */

    --neutral-100: 216 17% 95%; /* Neutral / 100 #F2F4F7 */
    --neutral-150: 0 0% 95.7%; /* Neutral / 150 #F4F4F4 */
    --neutral-200: 216 17% 85%; /* Neutral / 200 #DDE3EB */
    --neutral-400: 215 21.3% 64.1%; /* Neutral / 400 #90a0b7 */
    --neutral-800: 216 17% 12%; /* Neutral / 800 #191D23 */

    --h2-font-size: 2.375rem; /* 38px */
    --leading-11: 3.25rem; /* 52px */

    --gray-background-content: 225 25% 97%; /* #f5f6f9 */
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 229 97% 15%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  body {
    @apply bg-background text-foreground;
    font-family: articulat-cf, sans-serif;
    font-style: normal;
    font-weight: 400;
  }

  h2 {
    @apply font-medium text-h2 leading-11;
  }

  h4 {
    @apply font-semibold text-xl;
  }
}

/* test locofy */
:root {
  /* Colors */
  --neutral-background: #f7f5f2;
  --neutral-00: #fff;
  --neutral-01: #fcfcfc;
  --neutral-02: #f4f4f4;
  --neutral-03: #efefef;
  --neutral-04: #858ba4;
  --neutral-05: #333b59;
  --neutral-07: #1a1f2c;
  --primary-01: #2a85ff;
  --secondary-03: #b1e5fc;
  --tertiary-01: #ddebff;
  --tertiary-03: #e6f5e6;
  --tertiary-04: #fde8df;
  --shades-03: rgba(91, 99, 132, 0.4);
  --color-darkslategray-100: #272e44;
  --color-black: #000;

  /* Effects */
  --border-bottom-grey: 0px -1px 0px #efefef inset;
}
